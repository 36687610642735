import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import Home from './views/Home'
import Login from './views/Login'
import RequestPassword from './views/RequestPassword'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/inloggen',
        name: 'Login',
        component: Login
    },
    {
        path: '/opvragenWachtwoord',
        name: 'Opvragen wachtwoord',
        component: RequestPassword
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/inloggen')
    } else {
        next()
    }
})

export default router