import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tokenCreated: localStorage.getItem('tokenCreated') || '',
    },
    mutations: {
        authSuccess(state, tokenCreated) {
            state.tokenCreated = tokenCreated
        },
        logout(state) {
            state.status = ''
            state.tokenCreated = ''
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                auth(user)
                    .then(resp => {
                        const now = new Date()
                        const tokenCreated = now.getTime()
                        localStorage.setItem('tokenCreated', tokenCreated)
                        commit('authSuccess', tokenCreated)
                        resolve(resp)
                    })
                    .catch(err => {
                        localStorage.removeItem('tokenCreated')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('tokenCreated')
                resolve()
            })
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => !!state.tokenCreated,
    }
})