<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <LoginForm/>
        <v-row justify="center">
          <v-col cols="12" md="6" sm="8">
            Heb je nog geen wachtwoord of weet je deze niet meer? Vraag hem dan hieronder opnieuw op...<br><br>
            <v-btn to="/opvragenWachtwoord">Opvragen wachtwoord</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
  name: "Login",

  components: {
    LoginForm,
  }
}
</script>

<style scoped>

</style>