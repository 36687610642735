<template>
  <v-row
      align="center"
      justify="center"
  >
    <v-col
        cols="12"
        md="6"
        sm="8"
    >
      <v-alert v-if="errorMessage" text type="error">
        {{ errorMessage }}
      </v-alert>

      <div v-if="resultaat">
        <h3>Planning voor {{ resultaat.agent }}</h3>
        <v-simple-table>
          <thead>
          <tr>
            <th>Dag</th>
            <th>Datum</th>
            <th>Tijd</th>
            <th>Opmerking</th>
          </tr>
          </thead>
          <tr v-for="uren in resultaat.uren" :key="uren.datum">
            <td>{{ uren.weekdag }}</td>
            <td>{{ uren.datum }}</td>
            <td>{{ uren.start }}-{{ uren.eind }}</td>
            <td>{{ uren.opmerking }}</td>
          </tr>
        </v-simple-table>

        <br>
        <p><i>Planning is gemaakt tot en met {{ resultaat.geplandTotMet }} (bijgewerkt om {{ updateTime }})</i></p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {getPlanning} from "@/api";

export default {
  name: "Planning",

  data: function () {
    return {
      errorMessage: null,
      resultaat: null,
      updateTime: null,
      timeHidden: null,
    }
  },

  created: function () {
    this.updateContent()

    document.addEventListener('visibilitychange', () => {
      let state = document.visibilityState;
      if (state === 'hidden') {
        this.timeHidden = (new Date()).getTime()
      } else if (state === 'visible' && this.timeHidden) {
        let lastSec = parseInt(this.timeHidden / 1000)
        let nowSec = parseInt((new Date()).getTime() / 1000)
        if ((nowSec - lastSec) > 60) {
          this.updateContent()
        }
      }
    })
  },

  methods: {
    async updateContent() {
      getPlanning()
          .then(resp => {
            this.resultaat = resp
            this.errorMessage = null
            let d = new Date();
            this.updateTime = d.toLocaleTimeString([], {timeStyle: 'short'})
          })
          .catch(err => {
            this.errorMessage = err
          })
    }
  }
}
</script>

<style scoped>

</style>