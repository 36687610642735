<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <span v-if="isLoggedIn"><Planning/></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Planning from "@/components/Planning";

export default {
  name: 'Home',

  components: {
    Planning,
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
  },
}
</script>
